<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title"> {{ $t('li_step.payment_history_report') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(loadData)" @reset.prevent="reset" >
                    <b-row>
                      <b-col lg="6" sm="6">
                        <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{ $t('user_role.organization') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="search.org_id"
                                    :options="orgList"
                                    id="org_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :disabled="authOrgId > 0"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="6">
                        <ValidationProvider name="Service Name" vid="service_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="service_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{ $t('org_pro.service_namel') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                    v-model="search.service_id"
                                    :options="serviceNamesList"
                                    id="service_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="6">
                        <b-row>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Year" vid="year" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="year"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                      {{ $t('globalTrans.from') }}
                                    </template>
                                    <b-form-select
                                        v-model="search.year"
                                        :options="yearList"
                                        id="year"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{ $t('globalTrans.year') }}</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6" style="padding-left: 0px;">
                            <ValidationProvider name="Month" vid="month" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="month"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                      <br/>
                                    </template>
                                    <b-form-select
                                        v-model="search.month"
                                        :options="monthList"
                                        id="month"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{ $t('globalTrans.month') }}</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col lg="6" sm="6">
                        <b-row>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Year" vid="year" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="year"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                      {{ $t('globalTrans.to_projonto') }}
                                    </template>
                                    <b-form-select
                                        v-model="search.year_to"
                                        :options="yearList"
                                        id="year"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{ $t('globalTrans.year') }}</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6" style="padding-left: 0px;">
                            <ValidationProvider name="Month" vid="month" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="month"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                      <br/>
                                    </template>
                                    <b-form-select
                                        v-model="search.month_to"
                                        :options="monthList"
                                        id="month"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{ $t('globalTrans.month') }}</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="Application Type">
                          <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="application_type"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('globalTrans.type') }}
                            </template>
                            <b-form-select
                              plain
                              v-model="search.application_type"
                              :options="applicationTypeList"
                              id="application_type"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="Payment Type">
                          <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="paid_type"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('globalTrans.payment_type') }}
                            </template>
                            <b-form-select
                              plain
                              v-model="search.paid_type"
                              :options="paymentTypeList"
                              id="paid_type"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.search') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row v-if="showData">
          <b-col md="12">
            <b-overlay :show="loading">
              <iq-card>
                <template v-slot:body>
                  <b-row>
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('li_step.payment_history_report') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                          <b-button class="btn_add_new mr-2" @click="pdfExport">
                            <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                          </b-button>
                        </template>
                        <template v-slot:body>
                          <b-overlay>
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <div style="border: 2px solid;margin:10px;padding:10px">
                                  <b-row>
                                    <b-col>
                                      <list-report-head :baseUrl="licenseRegistrationServiceBaseUrl" :url="'/configuration/report-heading/detail'" :org-id="search.org_id">
                                        {{ $t('li_step.payment_history_report') }}
                                      </list-report-head>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col>
                                      <b-row class="mb-3">
                                        <b-col md="6" class="text-left">
                                          {{ $t('user_role.organization') }}: <strong>{{ search.org_id ? getOrganizationName(search.org_id) : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                        <b-col md="6" class="text-left">
                                          {{ $t('org_pro.service_namel') }}: <strong>{{ search.service_id ? getServiceName(search.service_id) : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                      </b-row>
                                      <b-row class="mb-3">
                                        <b-col md="6" class="text-left">
                                          {{ $t('globalTrans.from') }}: <strong>{{ search.year ? $n(search.year, { useGrouping: false }) : $t('globalTrans.all') }}</strong>, <strong>{{ search.month ? ', ' + getMonthName(search.month) : '' }}</strong>
                                        </b-col>
                                        <b-col md="6" class="text-left">
                                          {{ $t('globalTrans.to_projonto') }}: <strong>{{ search.year_to ? $n(search.year_to, { useGrouping: false }) : $t('globalTrans.all') }}</strong> <strong>{{ search.month_to ? ', ' + getMonthName(search.month_to) : '' }}</strong>
                                        </b-col>
                                      </b-row>
                                      <b-row class="mb-3">
                                        <b-col md="6" class="text-left">
                                          {{ $t('globalTrans.type') }}: <strong>{{ search.application_type ? getApplicationTypeName(search.application_type) : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                        <b-col md="6" class="text-left">
                                          {{ $t('globalTrans.payment_type') }}: <strong>{{ search.paid_type ? getPaymentTypeName(search.paid_type) : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col>
                                      <b-table-simple hover small responsive bordered>
                                        <b-thead>
                                          <b-tr>
                                            <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                                            <b-th>{{ $t('globalTrans.year') }}</b-th>
                                            <b-th>{{ $t('globalTrans.month') }}</b-th>
                                            <b-th>{{ $t('li_step.total_application') }}</b-th>
                                            <b-th>{{ $t('li_step.online_application') }}</b-th>
                                            <b-th>{{ $t('li_step.offline_application') }}</b-th>
                                            <b-th>
                                              {{ $t('li_step.online_payment') }}
                                              <strong v-if="search.org_id === 2">{{ $t('li_step.with_vat') }}</strong>
                                            </b-th>
                                            <b-th>
                                              {{ $t('li_step.offline_payment') }}
                                              <strong v-if="search.org_id === 2">{{ $t('li_step.with_vat') }}</strong>
                                            </b-th>
                                            <b-th>{{ $t('globalTrans.total') }}</b-th>
                                          </b-tr>
                                        </b-thead>
                                        <b-tbody>
                                          <template v-if="listData.length">
                                            <template v-for="(yearItem, yearItemIndex) in listData">
                                              <b-tr v-for="(monthItem, monthItemIndex) in yearItem.month_info" :key="'y-' + yearItemIndex + '-month-' + monthItemIndex">
                                                <b-td>{{ $n(monthItem.serial_no, { useGrouping: false }) }}</b-td>
                                                <b-td v-if="monthItemIndex === 0" :rowspan="yearItem.month_info.length">{{ $n(yearItem.year, { useGrouping: false }) }}</b-td>
                                                <b-td>{{ getMonthName(monthItem.month) }}</b-td>
                                                <b-td class="text-right">{{ $n(monthItem.total_application) }}</b-td>
                                                <b-td class="text-right">{{ $n(monthItem.online_application) }}</b-td>
                                                <b-td class="text-right">{{ $n(monthItem.offline_application) }}</b-td>
                                                <b-td class="text-right">{{ $n(monthItem.online_payment) }}</b-td>
                                                <b-td class="text-right">{{ $n(monthItem.offline_payment) }}</b-td>
                                                <b-td class="text-right">{{ $n(monthItem.total_payment) }}</b-td>
                                              </b-tr>
                                            </template>
                                          </template>
                                          <b-tr v-else>
                                            <b-td colspan="9" class="text-center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                          </b-tr>
                                        </b-tbody>
                                        <b-tfoot v-if="listData.length">
                                          <b-tr>
                                              <b-th colspan="3" class="text-right">{{ $t('globalTrans.total') }}</b-th>
                                              <b-th class="text-right">{{ $n(totalInfo.total_application) }}</b-th>
                                              <b-th class="text-right">{{ $n(totalInfo.total_online_application) }}</b-th>
                                              <b-th class="text-right">{{ $n(totalInfo.total_offline_application) }}</b-th>
                                              <b-th class="text-right">{{ $n(totalInfo.total_online_payment) }}</b-th>
                                              <b-th class="text-right">{{ $n(totalInfo.total_offline_payment) }}</b-th>
                                              <b-th class="text-right">{{ $n(totalInfo.total_payment) }}</b-th>
                                            </b-tr>
                                        </b-tfoot>
                                      </b-table-simple>
                                    </b-col>
                                  </b-row>
                                </div>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { paymentHistoryReport } from '../../api/routes'
import ListReportHead from '@/components/custom/ListReportHeadLrcpnOn.vue'
import ExportPdf from './export_pdf'
import { helpers } from '@/mixins/helper-functions.js'

export default {
  props: [],
  components: {
    ValidationObserver,
    ValidationProvider,
    ListReportHead
  },
  data () {
    return {
      search: {
        org_id: 0,
        service_id: 0,
        year: 0,
        year_to: 0,
        month: 0,
        month_to: 0,
        application_type: 0,
        paid_type: 0
      },
      licenseRegistrationServiceBaseUrl: licenseRegistrationServiceBaseUrl,
      listData: [],
      serviceNamesList: [],
      totalInfo: {},
      excelColumnBn: {},
      excelColumn: {},
      showData: false
    }
  },
  computed: {
    loading () {
        return this.$store.state.commonObj.loading
    },
    orgList () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    yearList () {
      return helpers.getYearListCurrent({ yearFrom: 2021 })
    },
    monthList () {
      return this.$store.state.commonObj.monthList
    },
    applicationTypeList () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'New' : 'নতুন', text_en: 'New', text_bn: 'নতুন' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Reneiw' : 'রিনিউ', text_en: 'Reneiw', text_bn: 'রিনিউ' }
      ]
      return list
    },
    paymentTypeList () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'অনলাইন' : 'Online',
          text_en: 'Online',
          text_bn: 'অনলাইন'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'অফলাইন' : 'Offline',
          text_en: 'Offline',
          text_bn: 'অফলাইন'
        }
      ]
    },
    authOrgId () {
      const authUser = this.$store.state.Auth.authUser
      const activeRoleId = this.$store.state.Auth.activeRoleId
      return activeRoleId !== 1 ? authUser.org_id : 0
    }
  },
  watch: {
    'search.org_id': function (newVal) {
      if (newVal) {
        this.serviceNamesList = this.getServiceList(newVal)
      } else {
        this.serviceNamesList = []
      }
    }
  },
  created () {
    if (this.authOrgId) {
      this.search.org_id = this.authOrgId
    }
  },
  mounted () {
    core.index()
  },
  methods: {
    pdfExport () {
      const reportTitle = this.$t('li_step.payment_history_report')
      ExportPdf.exportPdfDetails(licenseRegistrationServiceBaseUrl, '/configuration/report-heading/detail', this.search.org_id, reportTitle, this)
    },
    async loadData () {
      this.showData = true
      this.listData = []
      this.totalInfo = {}
      this.$store.commit('mutateCommonProperties', {
          loading: true
      })
      const result = await RestApi.getData(licenseRegistrationServiceBaseUrl, paymentHistoryReport, this.search)
      if (result.success) {
        this.$store.commit('mutateCommonProperties', {
          loading: false
        })
        this.listData = result.data
        this.totalInfo = result.total_info
      } else {
        this.listData = []
        this.totalInfo = {}
        this.$store.commit('mutateCommonProperties', {
          loading: false
        })
      }
    },
    getServiceList (orgId) {
      const tmpService = this.$store.state.licenseRegistration.commonObj.serviceNamesList.filter(item => (item.org_id === orgId))
      return tmpService.map(item => {
        return Object.assign({}, item, { text: this.$i18n.locale === 'bn' ? item.text_bn : item.text_en })
      })
    },
    getOrganizationName (id) {
      const obj = this.$store.state.commonObj.organizationProfileList.find(item => item.value === id)
      return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
    },
    getServiceName (id) {
      const obj = this.$store.state.licenseRegistration.commonObj.serviceNamesList.find(item => item.value === id)
      return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
    },
    getMonthName (id) {
      const obj = this.$store.state.commonObj.monthList.find(item => item.value === id)
      return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
    },
    getApplicationTypeName (id) {
      const obj = this.applicationTypeList.find(item => item.value === id)
      return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
    },
    getPaymentTypeName (id) {
      const obj = this.paymentTypeList.find(item => item.value === id)
      return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
    }
  }
}
</script>
<style >
.table-bordered th{
border: 1px solid #000 !important;
}
</style>
